import React from "react";
import styled from "styled-components";

class FaqLabelGroup extends React.Component {
  render() {
    let { label, selectedLabel, onSelect } = this.props;

    return (
      <Wrapper onClick={() => onSelect(label)}>
        <div style={{ flex: 1 }}>{label}</div>
        {selectedLabel === label ? "-" : "+"}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid #eeeeee;
`;

export default FaqLabelGroup;
