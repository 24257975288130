import React from "react";
import styled from "styled-components";
import FaqArticleDetail from "./FaqArticleDetail";

class FaqArticleGroup extends React.Component {
  render() {
    let { group } = this.props;

    return (
      <Wrapper>
        <h3 style={{ marginBottom: 15, fontWeight: 500 }}>{group[0].label}</h3>
        {group.map(article => (
          <FaqArticleDetail key={article.id} article={article} />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

export default FaqArticleGroup;
