import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { withPage } from "../PageContainer";
import FaqLabelGroup from "../Components/FaqLabelGroup";
import FaqArticleGroup from "../Components/FaqArticleGroup";

class FaqPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLabel: "",
      showDrawer: false
    };
  }

  render() {
    let { faqLabels, faqs } = this.props.pageContext;
    let { selectedLabel, showDrawer } = this.state;

    return (
      <Wrapper>
        <Content>
          <Ant.Row>
            <Ant.Col
              xs={0}
              sm={0}
              md={0}
              lg={6}
              style={{
                minHeight: "calc(100vh - 60px)",
                padding: "30px 10px"
              }}
            >
              <h3 style={{ marginBottom: 10, fontWeight: 500, color: "teal" }}>
                客服中心
              </h3>
              {faqLabels.map((label, idx) => (
                <FaqLabelGroup
                  key={`${idx}${label}`}
                  label={label}
                  selectedLabel={selectedLabel}
                  onSelect={label =>
                    this.setState({
                      selectedLabel: selectedLabel === label ? null : label
                    })
                  }
                />
              ))}
            </Ant.Col>

            <Ant.Col
              xs={24}
              sm={24}
              md={24}
              lg={18}
              style={{ padding: "30px 20px" }}
            >
              <ToggleDrawerButton
                onClick={() => this.setState({ showDrawer: true })}
              >
                <div className="label">FAQ 分類</div>
              </ToggleDrawerButton>

              {!selectedLabel ? (
                <h3 style={{ marginBottom: 15, fontWeight: 500 }}>
                  請選擇標籤
                </h3>
              ) : (
                <FaqArticleGroup
                  group={faqs.filter(f => f.label === selectedLabel)}
                />
              )}
            </Ant.Col>
          </Ant.Row>
        </Content>

        <Ant.Drawer
          title="客服中心"
          placement="left"
          closable={false}
          onClose={() => this.setState({ showDrawer: false })}
          visible={showDrawer}
        >
          {faqLabels.map((label, idx) => (
            <FaqLabelGroup
              key={`${idx}${label}-rwd`}
              label={label}
              selectedLabel={selectedLabel}
              onSelect={label =>
                this.setState({
                  selectedLabel: selectedLabel === label ? null : label,
                  showDrawer: false
                })
              }
            />
          ))}
        </Ant.Drawer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const ToggleDrawerButton = styled.div`
  display: none;

  & .label {
    border: 1px solid #eee;
    max-width: 100px;
    padding: 5px 10px;
    text-align: center;
  }

  @media only screen and (max-width: 992px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
`;

export default withPage(FaqPage);
