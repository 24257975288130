import React from "react";
import styled from "styled-components";

class FaqArticleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    let { article } = this.props;
    let { open } = this.state;

    return (
      <Wrapper>
        <div className="title" onClick={() => this.setState({ open: !open })}>
          <div style={{ flex: 1 }}>{article.title}</div>
          {open ? "-" : "+"}
        </div>
        <div className="sections">
          {open &&
            article.sections.map((section, idx) => {
              switch (section.type) {
                case "video":
                  return (
                    <a
                      key={"section" + idx}
                      className="video"
                      target="_blank"
                      href={section.content}
                    >
                      {section.content}
                    </a>
                  );
                case "image":
                  return (
                    <img
                      key={"section" + idx}
                      className="image"
                      src={`/images/pages/faq/${section.content}`}
                    />
                  );
                default:
                  return (
                    <div key={"section" + idx} className="text">
                      {section.content}
                    </div>
                  );
              }
            })}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & .title {
    border: 1px solid #eeeeee;
    padding: 10px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  & .sections {
    margin-bottom: 10px;
  }

  & .video {
    display: block;
    padding: 10px;
    margin-bottom: 5px;
  }

  & .image {
    display: block;
    max-width: 350px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 5px;
  }

  & .text {
    white-space: pre-wrap;
    padding: 10px;
    margin-bottom: 5px;
  }
`;

export default FaqArticleDetail;
